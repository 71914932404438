import { VFC, useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { Grid, Card, CardContent } from '@material-ui/core';
import { sendToGoogleAnalytics } from './Analytics';
import './Search.scss';

type ResponseError = {
    message: string,
};

type SearchRecipeResponse = {
    option: SearchRecipeOptionResponse,
    result: SearchRecipeResultItemResponse[],
};

type SearchRecipeOptionResponse = {
    keyword?: string,
    foodstuffs: string[][],
    tags: string[][],
};

type SearchRecipeResultItemResponse = {
    id: string,
    name: string,
    hit: string,
    image?: string,
};

type SearchFoodstuffResponseItem = {
    id: string,
    name: string,
    hit: string,
    image?: string,
};

type SearchTagResponseItem = {
    id: string,
    name: string,
    hit: string,
};

const api_url = process.env.REACT_APP_API_URL;

type RouteProps = {
    language: string,
};

export const Search: VFC<RouteProps> = (props: RouteProps) => {
    const language = props.language;
    const [searchParams] = useSearchParams();
    const [loadCondition, setLoadCondition] = useState<number>(0);
    const [keyword, setKeyword] = useState<string>("");
    const [foodstuffs, setFoodstuffs] = useState<string[][]>([]);
    const [foodstuffKeyword, setFoodstuffKeyword] = useState<string>("");
    const [searchFoodstuffItems, setSearchFoodstuffItems] = useState<SearchFoodstuffResponseItem[]>([]);
    const [searchTagItems, setSearchTagItems] = useState<SearchTagResponseItem[]>([]);
    // const [searchTagItems, setSearchTagItems] = use
    const [tags, setTags] = useState<string[][]>([]);
    const [entries, setEntries] = useState<SearchRecipeResultItemResponse[]>([]);

    function describeSearchResult(foodstuffs: string[][], tags: string[][]) {
        let keys = foodstuffs.concat(tags).map((x) => x[1]);

        if (language === "ja") {
            if (keys.length > 0) {
                return keys.join("、") + "の検索結果";
            } else {
                return "検索結果";
            }
        } else {
            if (keys.length > 0) {
                return "Search Result for " + keys.join(", ");
            } else {
                return "Search Result";
            }
        }
    }

    function describeLeadToLink() {
        if (language === "ja") {
            return "レシピのページへ →";
        } else {
            return "Go to recipe →";
        }
    }

    function describeSearchButton() {
        if (language === "ja") {
            return "検索";
        } else {
            return "Search";
        }
    }

    useEffect(() => {
        axios.get<SearchRecipeResponse>(
            api_url + "/api/" + language + "/search/recipe?" + searchParams.toString()
        ).then((value: AxiosResponse<SearchRecipeResponse>) => {
            setKeyword(value.data.option.keyword ?? "");
            setFoodstuffs(value.data.option.foodstuffs);
            setTags(value.data.option.tags);
            setEntries(value.data.result);
            setLoadCondition(1)
        }).catch((e: AxiosError<ResponseError>) => {
            setLoadCondition(-1);
        });
    }, []);

    if (loadCondition === 0) {
        return <div>Loading...</div>;
    } else if (loadCondition === -1) {
        return <div>Load Failed.</div>;
    }

    return <div id="search">
        <div id="search-option">
            <div className="search-option-column">
                <input id="search-option-keyword" type="text" value={keyword} onChange={(e) => {setKeyword(e.target.value)}} />
            </div>
            <div className="search-option-column">
                <div id="search-option-foodstuffs">
                    <div className="headline">Foodstuff</div>
                    {foodstuffs.map((fs, i_fs) =>
                        <div key={i_fs} className="foodstuff">
                            <div className="id">{fs[0]}</div>
                            <div className="name">{fs[1]}</div>
                            <div className="delete" onClick={(e) => {
                                let target = e.currentTarget.parentElement?.getElementsByClassName("id")[0].textContent ?? "";
                                setFoodstuffs(foodstuffs.filter((x) => x[0] != target));
                            }}>✗</div>
                        </div>
                    )}
                    <div className="add-value">
                        <input type="text" />
                        <button type="button" onClick={(e) => {
                            let text = e.currentTarget.parentElement?.getElementsByTagName("input")[0].value;
                            axios.get<SearchFoodstuffResponseItem[]>(
                                api_url + "/api/" + language + "/search/foodstuff?keyword=" + text
                            ).then((value: AxiosResponse<SearchFoodstuffResponseItem[]>) => {
                                setSearchFoodstuffItems(value.data);
                            });
                        }}>{describeSearchButton()}</button>
                    </div>
                    <div className="add-value-items">
                        {searchFoodstuffItems.map((item, i_item) => {
                            return <div className="item" key={i_item} onClick={(e) => {
                                for (let i = 0; i < foodstuffs.length; i++) {
                                    if (foodstuffs[i][0] === item.id) {
                                        return;
                                    }
                                }
                                setFoodstuffs([...foodstuffs, [item.id, item.name]]);
                            }}>
                                <span className="name">{item.name}</span>
                                <span className="hit">{item.name === item.hit ? "" : item.hit}</span>
                            </div>;
                        })}
                    </div>
                </div>
            </div>
            <div className="search-option-column">
                <div id="search-option-tags">
                    <div className="headline">Tag</div>
                    {tags.map((tg, i_tg) =>
                        <div key={i_tg} className="tag">
                            <div className="id">{tg[0]}</div>
                            <div className="name">{tg[1]}</div>
                            <div className="delete" onClick={(e) => {
                                let target = e.currentTarget.parentElement?.getElementsByClassName("id")[0].textContent ?? "";
                                setTags(tags.filter((x) => x[0] != target));
                            }}>✗</div>
                        </div>
                    )}
                    <div className="add-value">
                        <input type="text" />
                        <button type="button" onClick={(e) => {
                            let text = e.currentTarget.parentElement?.getElementsByTagName("input")[0].value;
                            axios.get<SearchTagResponseItem[]>(
                                api_url + "/api/" + language + "/search/tag?keyword=" + text
                            ).then((value: AxiosResponse<SearchTagResponseItem[]>) => {
                                setSearchTagItems(value.data);
                            });
                        }}>{describeSearchButton()}</button>
                    </div>
                    <div className="add-value-items">
                        {searchTagItems.map((item, i_item) => {
                            return <div className="item" key={i_item} onClick={(e) => {
                                for (let i = 0; i < tags.length; i++) {
                                    if (tags[i][0] === item.id) {
                                        return;
                                    }
                                }
                                setTags([...tags, [item.id, item.name]]);
                            }}>
                                <span className="name">{item.name}</span>
                                <span className="hit">{item.name === item.hit ? "" : item.hit}</span>
                            </div>;
                        })}
                    </div>
                </div>
            </div>
            <div className="search-option-submit">
                <button type="button" onClick={(e) => {
                    let has_param = false;
                    let params: { keyword?: string, foodstuff?: string, tag?: string } = {};

                    if (keyword.trim().length > 0) {
                        params["keyword"] = keyword.trim();
                        has_param = true;
                    }
                    if (foodstuffs.length > 0) {
                        params["foodstuff"] = foodstuffs.map((f) => f[0]).join(",");
                        has_param = true;
                    }
                    if (tags.length > 0) {
                        params["tag"] = tags.map((t) => t[0]).join(",");
                        has_param = true;
                    }

                    if (has_param) {
                        window.location.href = "/" + language + "/search?" + new URLSearchParams(params).toString();
                    }
                }}>検索</button>
            </div>
        </div>
        <div style={{clear: "both"}}></div>
        <div className="headline">{describeSearchResult(foodstuffs, tags)}</div>
        <div id="results">
            {entries.map((entry, i_entry) => {
                return <div className="entry" key={i_entry}>
                    <div className="icon">
                        <img className="thumbnail" src={entry.image === null ? "" : "/img/recipes/" + entry.id[0] + "/" + entry.id + "/" + entry.image} />
                    </div>
                    <div className="property">
                        <div style={{padding: "0.3rem"}}>
                            <div className="title">{entry.name}</div>
                            <div className="hit">{entry.hit}</div>
                            <a className="link" href={"/" + language + "/recipes/" + entry.id}>{ describeLeadToLink() }</a>
                        </div>
                    </div>
                </div>;
            })}
        </div>
    </div>;
};
