import { Home } from './Home';
import { Header } from './Header';
import { Recipe } from './Recipe';
import { Foodstuff } from './Foodstuff';
import { Search } from './Search';
import { EditorHome } from "./editor/Home";
import { EditorRecipe } from "./editor/Recipe";
import { EditorGallery } from "./editor/Gallery";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import './App.css';


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={
                    <>
                        <Header language={"ja"} />
                        <Home language={"ja"} />
                    </>
                } />
                <Route path="/ja">
                    <Route path="foodstuffs">
                        <Route path=":foodstuff_id" element={
                            <>
                                <Header language={"ja"} />
                                <Foodstuff language={"ja"} />
                            </>
                    } />
                    </Route>
                    <Route path="recipes">
                        <Route path=":recipe_id" element={
                            <>
                                <Header language={"ja"} />
                                <Recipe language={"ja"} />
                            </>
                        } />
                    </Route>
                    <Route path="search" element={
                        <>
                            <Header language={"ja"} />
                            <Search language={"ja"} />
                        </>
                    } />
                </Route>
                <Route path="/en">
                    <Route path="" element={
                        <>
                            <Header language={"en"} />
                            <Home language={"en"} />
                        </>
                    } />
                    <Route path="foodstuffs">
                        <Route path=":foodstuff_id" element={
                            <>
                                <Header language={"en"} />
                                <Foodstuff language={"en"} />
                            </>
                        } />
                    </Route>
                    <Route path="recipes">
                        <Route path=":recipe_id" element={
                            <>
                                <Header language={"en"} />
                                <Recipe language={"en"} />
                            </>
                        } />
                    </Route>
                    <Route path="search" element={
                        <>
                            <Header language={"en"} />
                            <Search language={"en"} />
                        </>
                    } />
                </Route>
                <Route path="/editor">
                    <Route path="" element={
                        <>
                            <Header language={"ja"} />
                            <EditorHome />
                        </>
                    } />
                    <Route path="recipes">
                        <Route path=":recipe_id" element={
                            <>
                                <Header language={"ja"} />
                                <EditorRecipe />
                            </>
                        } />
                    </Route>
                    <Route path="gallery" element={
                        <>
                            <Header language={"ja"} />
                            <EditorGallery />
                        </>
                    } />
                </Route>
                <Route path="/*" element={
                    <>
                        <Header language={"ja"} />
                        <NotFound />
                    </>
                } />
            </Routes>
        </BrowserRouter>
    );
}

function NotFound() {
    return <div>Not found</div>;
}

export default App;
