import { VFC, useState, useEffect } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import { Grid, Card, CardContent } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { sendToGoogleAnalytics } from './Analytics';
import { Helmet } from 'react-helmet-async';
import "react-image-gallery/styles/css/image-gallery.css";
import './Recipe.scss';


type RecipeResponseError = {
    message: string,
};

type RecipeIngredientResponse = {
    id: string,
    tag: string,
    name: string,
    link: string,
    category: number,
    quantity: string,
    optional: boolean,
    step_references: RecipeIngredientStepReference[],
};

type RecipeIngredientStepReference = {
    number: number,
    reference: string,
};

type RecipeStepResponse = {
    id: string,
    number: number,
    leavable: boolean,
    optional: boolean,
    processes: RecipeStepProcessResponse[],
};

type RecipeStepProcessResponse = {
    descriptions: RecipeProcessDescriptionResponse[],
    references: string[],
}

type RecipeProcessDescriptionResponse = {
    tag: string,
    value: string,
    link?: string,
    reference?: string,
    quantity?: string,
    optional: boolean,
}

type RecipeResponse = {
    id: string,
    images: string[],
    name: string,
    sub_names: string[],
    description: string,
    meal: number,
    meal_default: number,
    tags: string[][],
    ingredients: RecipeIngredientResponse[],
    steps: RecipeStepResponse[],
    substitutions: RecipeSubstitutionResponse[],
};

type RecipeSubstitutionResponse = {
    origins: RecipeSubstitutionOriginItemResponse[],
    targets: RecipeSubstitutionTargetItemResponse[],
};

type RecipeSubstitutionOriginItemResponse = {
    ingredient_id: string,
    tag: string,
    value: string,
    reference: string,
    quantity: string,
};

type RecipeSubstitutionTargetItemResponse = {
    tag: string,
    value: string,
    reference: string,
    quantity: string,
};

const useStyles = makeStyles((theme) => ({
    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" } // KEY CHANGES
}));


type RouteProps = {
    language: string,
};


export const Recipe: VFC<RouteProps> = (props: RouteProps) => {
    const params = useParams();
    const recipe_id = params.recipe_id ?? "";
    const meal_options = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
        11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24
    ];

    const language = props.language;
    const [loadCondition, setLoadCondition] = useState<number>(0);

    const [recipeName, setRecipeName] = useState<string>("");
    const [recipeSubNames, setRecipeSubNames] = useState<string[]>([]);
    const [recipeDescription, setRecipeDescription] = useState<string>("");
    const [recipeImages, setRecipeImages] = useState<string[]>([]);
    const [recipeMeal, setRecipeMeal] = useState<number>(0);
    const [recipeMealDefault, setRecipeMealDefault] = useState<number>(0);
    const [recipeTags, setRecipeTags] = useState<string[][]>([]);
    const [recipeIngredients, setRecipeIngredients] = useState<RecipeIngredientResponse[]>([]);
    const [recipeSteps, setRecipeSteps] = useState<RecipeStepResponse[]>([]);
    const [selectedReference, setSelectedReference] = useState<string>("");
    const [recipeSubstitutions, setRecipeSubstitutions] = useState<RecipeSubstitutionResponse[]>([]);

    const min_of_step = (ing: RecipeIngredientResponse) => Math.min(...(ing.step_references.length === 0 ? [99] : ing.step_references.map((stp) => stp.number)));
    const [ingredientsSortOrder, setIngredientsSortOrder] = useState<string>("step_number");
    const handleIngredientsSortOrder = (
        event: React.MouseEvent<HTMLElement>,
        value: string
    ) => {
        setIngredientsSortOrder(value);
        if (value === "step_number") {
            setRecipeIngredients(
                recipeIngredients.sort((a, b) => min_of_step(a) - min_of_step(b))
            );
        } else if (value === "category") {
            setRecipeIngredients(
                recipeIngredients.sort((a, b) => a.category - b.category)
            );
        }
    };

    useEffect(() => {
        getRecipe(language, undefined);
    }, []);

    function getRecipe(language: string, meal_num?: number) {
        let url = process.env.REACT_APP_API_URL + "/api/" + language + "/recipes/" + recipe_id;
        if (meal_num !== undefined) {
            url += "/" + meal_num;
        }

        axios.get<RecipeResponse>(url).then((value: AxiosResponse<RecipeResponse>) => {
            setRecipeName(value.data.name);
            setRecipeSubNames(value.data.sub_names);
            setRecipeDescription(value.data.description);
            setRecipeImages(value.data.images);
            setRecipeMeal(value.data.meal);
            setRecipeMealDefault(value.data.meal_default);
            setRecipeTags(value.data.tags);
            if (ingredientsSortOrder == "step_number") {
                setRecipeIngredients(value.data.ingredients.sort((a, b) => min_of_step(a) - min_of_step(b)));
            } else {
                setRecipeIngredients(value.data.ingredients.sort((a, b) => a.category - b.category));
            }
            setRecipeSteps(value.data.steps);
            setRecipeSubstitutions(value.data.substitutions);

            setSelectedReference("");
            setLoadCondition(1);

            sendToGoogleAnalytics("/" + language + "/recipes/" + recipe_id);
        }).catch((e: AxiosError<RecipeResponseError>) => {
            setLoadCondition(-1);
        });
    }

    function getReference() {
        return selectedReference;
    }

    function selectReference(refid: string) {
        if (selectedReference === refid) {
            setSelectedReference("");
        } else {
            setSelectedReference(refid);
        }
    }

    function changeLanguage(e: React.ChangeEvent<HTMLSelectElement>) {
        window.location.href = "/" + e.target.value + "/recipes/" + recipe_id;
    }

    function describeHeadlineLoading() {
        if (language === "ja") { return "読み込み中・・・"; }
        else { return "Loading..."; }
    }
    function describeHeadlineLoadFailed() {
        if (language === "ja") { return "読み込み失敗"; }
        else { return "Load Failed."; }
    }
    function describeHeadlineIngredients() {
        if (language === "ja") { return "材料"; }
        else { return "Ingredients"; }
    }
    function describeHeadlineMeals() {
        if (language === "ja") { return "食分"; }
        else { return " meals"; }
    }
    function describeHeadlineAutoCalculated() {
        if (language === "ja") { return "自動計算"; }
        else { return "auto calculated"; }
    }
    function describeHeadlineBasic() {
        if (language === "ja") { return "（標準）"; }
        else { return "(basic)"; }
    }
    function describeHeadlineCookingSteps() {
        if (language === "ja") { return "調理手順"; }
        else { return "Cooking Steps"; }
    }
    function describeHeadlineForSubstitution() {
        if (language === "ja") { return "代替品"; }
        else { return "For Substitution"; }
    }
    function describeAnnotationOptional() {
        if (language === "ja") { return "★・・・お好みで"; }
        else { return "★ ・・・ Optional"; }
    }
    function describeSortOrder() {
        if (language === "ja") { return "食材並び替え"; }
        else { return "Order of Ingredients"; }
    }
    function describeSortOrderStepNumber() {
        if (language === "ja") { return "手順"; }
        else { return "Step"; }
    }
    function describeSortOrderCategory() {
        if (language === "ja") { return "種類"; }
        else { return "Category"; }
    }

    let classes = useStyles();

    if (loadCondition === 0) {
        return <div id="recipe">{describeHeadlineLoading()}</div>;
    } else if (loadCondition === -1 || recipe_id === undefined) {
        return <div id="recipe">{describeHeadlineLoadFailed()}</div>;
    }

    let title_top = language === "ja" ? "ぼくレピ" : "BokuRepe (My Recipes)";
    let thumbnail = recipeImages.length > 0 ? process.env.REACT_APP_APP_URL + "/img/recipes/" + recipe_id[0] + "/" + recipe_id + "/" + recipeImages[0] : process.env.REACT_APP_APP_URL + "/img/sys/noimage.png";

    return <>
        <Helmet>
            <title>{recipeName} - {title_top}</title>
            <link rel="canonical" href={process.env.REACT_APP_APP_URL + "/" + language + "/recipes/" + recipe_id} />
            <meta property="og:url" content={process.env.REACT_APP_APP_URL + "/" + language + "/recipes/" + recipe_id} />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={recipeName + " - " + title_top} />
            <meta property="og:description" content={language === "ja" ? recipeName + "のレシピ" : "A Recipe of " + recipeName} />
            <meta property="og:site_name" content={title_top} />
            <meta property="og:image" content={thumbnail} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@tfull_tf" />
        </Helmet>
        <div id="recipe">
            <div id="recipe_title">
                <h1 id="recipe_name">{recipeName}</h1>
                <h2 id="recipe_sub_names">{recipeSubNames.join(", ")}</h2>
                <p id="recipe_description">{recipeDescription}</p>
            </div>
            <div id="head_options">
                <select
                    defaultValue={language}
                    onChange={(e) => changeLanguage(e)}
                >
                    {[["ja", "日本語"], ["en", "English"]].map((lgs, i_lgs) => 
                        <option key={i_lgs} value={lgs[0]}>{lgs[1]}</option>
                    )}
                </select>
            </div>
            <div id="recipe_tags">
                {recipeTags.map((tag, i_tag) =>
                    <a className="recipe_tag" key={i_tag} href={"/" + language + "/search?tag=" + tag[0]}>{tag[1]}</a>
                )}
            </div>
            <div id="recipe_subtop">
                <div id="recipe_gallery">
                    {
                        recipeImages.length > 0 ?
                        <ImageGallery items={
                            recipeImages.map((name, i) => { return { original: '/img/recipes/' + recipe_id[0] + "/" + recipe_id + "/" + name } })
                        } /> :
                        <img className="noimage" src={"/img/sys/noimage.png"} />
                    }
                </div>
                <div id="recipe_ingredients">
                    <div className="headline">
                        <span>{describeHeadlineIngredients()}</span>
                        <span>　（{recipeMeal} {describeHeadlineMeals()}{recipeMeal === recipeMealDefault ? "" : " ※ " + describeHeadlineAutoCalculated()}）</span>
                        <select
                            defaultValue={recipeMeal}
                            onChange={(e) => {
                                let n: number = Number(e.target.value);
                                if (n !== recipeMeal) {
                                    getRecipe(language, n);
                                }
                            }}
                        >
                            {meal_options.map((k, i) => {
                                if (k === recipeMealDefault) {
                                    return <option key={i} value={k}>{k} {describeHeadlineBasic()}</option>;
                                } else {
                                    return <option key={i}>{k}</option>;
                                }
                            })}
                        </select>
                    </div>
                    <div style={{textAlign: "right"}}>
                        <span style={{fontSize:"0.6rem"}}>{describeSortOrder()}</span>
                        <ToggleButtonGroup
                            value={ingredientsSortOrder}
                            exclusive
                            onChange={handleIngredientsSortOrder}
                            size="small"
                            aria-label="sort order"
                        >
                            <ToggleButton style={{fontSize:"0.7rem",padding:"0.1rem 0.3rem"}} value="step_number">{describeSortOrderStepNumber()}</ToggleButton>
                            <ToggleButton style={{fontSize:"0.7rem",padding:"0.1rem 0.3rem"}} value="category">{describeSortOrderCategory()}</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    {recipeIngredients.map((ing, i_ing) =>
                        <RecipeIngredient key={i_ing}
                            language={language}
                            id={ing.id}
                            tag={ing.tag}
                            link={ing.link}
                            name={ing.name}
                            quantity={ing.quantity}
                            optional={ing.optional}
                            step_references={ing.step_references}
                            getReference={getReference}
                            selectReference={selectReference}
                        />
                    )}
                </div>
                <div className="clear"></div>
            </div>
            <div id="recipe_sys_annotations">
                {recipeIngredients.some((x) => x.optional) || recipeSteps.some((x) => x.optional) ? <div className="sys_annotation ann_optional">{describeAnnotationOptional()}</div> : <></>}
            </div>
            <div id="recipe_steps">
                <div className="headline">{recipeSteps.length > 0 ? describeHeadlineCookingSteps() : ""}</div>
                <Grid container spacing={3} justifyContent="space-between" alignItems="stretch">
                    {recipeSteps.map((step, i_step) => 
                        <Grid item key={i_step} xs={12} sm={6} md={4} lg={3} className={classes.item}>
                            <RecipeStep key={i_step}
                                language={language}
                                id={step.id}
                                number={step.number}
                                leavable={step.leavable}
                                optional={step.optional}
                                processes={step.processes}
                                getReference={getReference}
                                selectReference={selectReference}
                            />
                        </Grid>
                    )}
                </Grid>
                <div className="clear"></div>
            </div>
            <div id="recipe_substitutions">
                <div className="headline">{recipeSubstitutions.length > 0 ? describeHeadlineForSubstitution() : ""}</div>
                {recipeSubstitutions.map((sub, i_sub) =>
                    <RecipeSubstitution key={i_sub}
                        language={language}
                        origins={sub.origins}
                        targets={sub.targets}
                        getReference={getReference}
                        selectReference={selectReference}
                    />
                )}
            </div>
        </div>
    </>;
};


type RecipeIngredientProps = {
    language: string,
    id: string,
    tag: string,
    link: string,
    name: string,
    quantity: string,
    optional: boolean,
    step_references: RecipeIngredientStepReference[],
    getReference: () => string,
    selectReference: (refid: string) => void
};

export const RecipeIngredient: VFC<RecipeIngredientProps> = (props) => {
    function describeSelectedState() {
        if (props.getReference() === props.id) {
            return " selected";
        } else {
            return "";
        }
    }

    function describeStepSelected(ref: string) {
        if (props.getReference() === ref) {
            return " selected";
        } else {
            return "";
        }
    }

    return <div className={"ingredient" + describeSelectedState()}>
        <span className="check" onClick={() => props.selectReference(props.id)}>●</span>
        <span className="name">
            <a href={"/" + props.language + "/" + props.tag + "s" + "/" + props.link}>{props.name}</a>
            {props.optional ? <span className="ann_optional"> ★</span> : <></>}
        </span>
        <span className="refs">
            {props.step_references.map((ref, i_r) => {
                return <span
                    key={i_r}
                    onClick={() => props.selectReference(ref.reference)}
                    className={"stepref" + describeStepSelected(ref.reference)}
                >{ref.number}</span>;
            })}
        </span>
        <span className="quantity">{props.quantity}</span>
    </div>;
};


type RecipeStepProps = {
    language: string,
    id: string,
    number: number,
    leavable: boolean,
    optional: boolean,
    processes: RecipeStepProcessResponse[],
    getReference: () => string,
    selectReference: (refid: string) => void
};

export const RecipeStep: VFC<RecipeStepProps> = (props) => {
    const [done, setDone] = useState<number>(0);

    function describeAnnotationWaiting() {
        if (props.language === "ja") {
            return "待機中";
        } else {
            return "Waiting";
        }
    }

    function describeAnnotationDone() {
        if (props.language === "ja") {
            return "完了";
        } else {
            return "Done";
        }
    }

    function setDoneState() {
        if (done === 0) {
            if (props.leavable) {
                setDone(1);
            } else {
                setDone(2);
            }
        } else if (done === 1) {
            setDone(2);
        } else {
            setDone(0);
        }
    }

    function describeDoneState() {
        if (done === 0) {
            return "yet";
        } else if (done === 1) {
            return "leave";
        } else {
            return "done";
        }
    }

    function describeSelectedState(rf: string) {
        if (props.getReference() === rf) {
            return " selected";
        } else {
            return "";
        }
    }

    function appendState() {
        if (done === 0) {
            if (props.optional) {
                return <span className="ann_optional">★</span>;
            } else {
                return <></>;
            }
        } else if (done === 1) {
            return <span className="ann_waiting">{describeAnnotationWaiting()}</span>;//<img className="state_logo" src={"/img/sys/leave.png"} />;
        } else {
            return <span className="ann_done">{describeAnnotationDone()}</span>;// <img className="state_logo" src={"/img/sys/done.png"} />;
        }
    }

    function ingredientState(optional: boolean) {
        if (optional) {
            return <span className="ann_optional">★</span>;
        } else {
            return <></>;
        }
    }

    return (
        <Card className={"step " + "state-" + describeDoneState() + describeSelectedState(props.id)}>
            <CardContent>
            <div className="step_headline">
                <div style={{width: "80%", float: "left"}}>
                    <span className={"number"} onClick={() => props.selectReference(props.id ?? "")}>{ props.number }</span>
                    {appendState()}
                </div>
                <div style={{width: "20%", float: "left", textAlign: "right"}} onClick={setDoneState}>
                    <div className="sys_state_change">
                        <span className="normal">●</span>
                        <span className="waiting">{props.leavable ? "●": ""}</span>
                        <span className="done">●</span>
                    </div>
                </div>
                <div style={{clear: "both"}}></div>
            </div>
            <div>
                {props.processes.map((pc, i_pc) => {
                    return <div key={i_pc}><span>・</span>{ pc.descriptions.map((ds, i_ds) => {
                        if (ds.tag === "text") {
                            return <span key={i_ds}>{ ds.value }</span>;
                        } else if (ds.tag === "explanation") {
                            return <span className="explanation" key={i_ds}>{ ds.value }</span>
                        } else if (ds.tag === "foodstuff") {
                            let link = ds.link ?? "";
                            let ref = ds.reference ?? "";
                            let uri = "/" + props.language + "/foodstuffs/" + link;
                            return <span key={i_ds} className={"ingredientref" + describeSelectedState(ref)}>
                                <span onClick={() => props.selectReference(ref)}>■</span>
                                <a href={uri}><span>{ds.value + " "}</span><span style={{fontSize: "0.7rem"}}>{ds.quantity}</span></a>
                                {ingredientState(ds.optional)}
                            </span>;
                        } else if (ds.tag === "recipe") {
                            let link = ds.link ?? "";
                            let ref = ds.reference ?? "";
                            let uri = "/" + props.language + "/recipes/" + link;
                            return <span key={i_ds} className={"ingredientref" + describeSelectedState(ref)}>
                                <span onClick={() => props.selectReference(ref)}>■</span>
                                <a href={uri}><span>{ds.value + " "}</span><span style={{fontSize: "0.7rem"}}>{ds.quantity}</span></a>
                                {ingredientState(ds.optional)}
                            </span>;
                        } else {
                            return <span key={i_ds} className={"stepref" + describeSelectedState(ds.reference ?? "")} onClick={() => props.selectReference(ds.reference ?? "")}>{ ds.value }</span>;
                        }
                    }) }</div>;
                })}
            </div>
            </CardContent>
        </Card>
    );
};

type RecipeSubstitutionProps = {
    language: string,
    origins: RecipeSubstitutionOriginItemResponse[],
    targets: RecipeSubstitutionTargetItemResponse[],
    getReference: () => string,
    selectReference: (refid: string) => void,
};

export const RecipeSubstitution: VFC<RecipeSubstitutionProps> = (props) => {

    function describeSelected(ref: string) {
        if (props.getReference() === ref) {
            return " selected";
        } else {
            return "";
        }
    }

    return (
        <div className="substitution">
            <ul className="substitution_origins">
                {props.origins.map((origin, i_origin) => {
                    let url = "/" + props.language + "/" + origin.tag + "s" + "/" + origin.reference;

                    return <li key={i_origin}>
                        <span className={"substitutionref" + describeSelected(origin.ingredient_id)}>
                            <span onClick={() => props.selectReference(origin.ingredient_id)}>■</span>
                            <a href={url}><span>{origin.value} </span><span style={{fontSize: "0.7rem"}}>{origin.quantity}</span></a>
                        </span>
                    </li>;
                })}
            </ul>
            <span className="substitution_to">→</span>
            <ul className="substitution_targets">
                {props.targets.map((target, i_target) => {
                    let url = "/" + props.language + "/" + target.tag + "s" + "/" + target.reference;

                    return <li key={i_target}>
                        <span className={"substitutionref"}>
                            <a href={url}><span>{target.value} </span><span style={{fontSize: "0.9rem"}}>{target.quantity}</span></a>
                        </span>
                    </li>;
                })}
            </ul>
        </div>
    );
};
