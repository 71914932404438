import ReactGA from "react-ga4";

export function sendToGoogleAnalytics(path: string) {
    if (process.env.REACT_APP_APP_ENV !== "prod") {
        return;
    }

    ReactGA.initialize("G-EZ29D8PL30");
    ReactGA.send({
        hitType: "pageview",
        page: path,
    });
}
