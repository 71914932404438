import { useRef, useState, useEffect } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import './Home.scss';

type SearchRecipeResponse = {
    id: string,
    name: string,
};

type CurrentRecipeCountResponse = {
    count: number,
};


const url = process.env.REACT_APP_API_URL + "/editor";


export const EditorHome = () => {
    const [recipeLinks, setRecipeLinks] = useState<SearchRecipeResponse[]>([]);
    const [recipeCount, setRecipeCount] = useState<number>(0);
    const [unreleasedOnly, setUnreleasedOnly] = useState<boolean>(false);
    const newRecipeId = useRef<HTMLInputElement>(null);

    useEffect(() => {
        axios.get<CurrentRecipeCountResponse>(url + "/count/recipes")
            .then((value: AxiosResponse<CurrentRecipeCountResponse>) => {
                setRecipeCount(value.data.count);
            });
    }, []);

    function searchRecipe(query_string: string) {
        axios.post<SearchRecipeResponse[]>(url + "/search/recipe", {
            query: query_string.split(" ").filter((x) => x !== ""),
            language: "ja",
            unreleased_only: unreleasedOnly,
        }).then((value: AxiosResponse<SearchRecipeResponse[]>) => {
            setRecipeLinks(value.data);
        });
    }

    if (process.env.REACT_APP_APP_ENV === "prod") {
        return <div>Not Found</div>;
    }

    return <div id="editor-home">
        <div id="recipe-count">公開レシピ数: {recipeCount}</div>
        <div id="editor-search-recipe">
            <div>検索</div>
            <input type="text" onChange={(e) => {
                if (e.target.value.split(" ").filter((x) => x.length > 0).length === 0) {
                    setRecipeLinks([]);
                    return;
                }
                searchRecipe(e.target.value);
            }} />
            <span className="toggle-option" onClick={() => setUnreleasedOnly(! unreleasedOnly)}>unreleased only: {unreleasedOnly ? "true" : "false"}</span>
        </div>
        <div id="editor-search-recipe-items">
            {recipeLinks.slice(0, 20).map((rl, i_rl) => {
                return <a key={i_rl} href={"/editor/recipes/" + rl.id} className="recipe-link">
                    <span className="recipe-id">{rl.id}</span>
                    <span className="recipe-name">{rl.name}</span>
                </a>;
            })}
        </div>
        <div id="editor-create-recipe">
            <div>新規作成</div>
            <input id="create-recipe" type="text" ref={newRecipeId} />
            <button type="button" onClick={(e) => {
                if (newRecipeId.current === null) {
                    return;
                }

                console.log(newRecipeId);
                let btn = e.currentTarget;
                axios.post(url + "/create/recipe", {
                    id: newRecipeId.current.value,
                }).then(() => {
                    btn.textContent = "Created";
                    setTimeout(() => { window.location.reload(); }, 1000);
                }).catch(() => {
                    btn.textContent = "Creation Error";
                    setTimeout(() => { btn.textContent = "Create"; }, 1000);
                });
            }}>Create</button>
        </div>

        <div id="editor-links">
            <a href="/editor/gallery">Image Gallery</a>
        </div>
    </div>;
};
