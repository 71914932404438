import { VFC, useState, useEffect, useRef } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { Grid, Card, CardContent } from '@material-ui/core';
import { sendToGoogleAnalytics } from './Analytics';
import "./Home.scss";


type RecommendEntriesResponse = {
    id: string,
    name: string,
    image?: string,
};


type RouteProps = {
    language: string,
};

const api_url = process.env.REACT_APP_API_URL;

export const Home: VFC<RouteProps> = (props: RouteProps) => {
    const queryRef = useRef<HTMLInputElement>(null);
    const language = props.language;
    const [topEntries, setTopEntries] = useState<RecommendEntriesResponse[]>([]);

    function describeWordSearch() {
        if (language === "ja") {
            return "検索";
        } else {
            return "Search";
        }
    }

    function describePickUp() {
        if (language === "ja") {
            return "ピックアップ!";
        } else {
            return "Pick up!";
        }
    }

    useEffect(() => {
        axios.get<RecommendEntriesResponse[]>(
            api_url + "/recommendation/" + language + "/top"
        ).then((value: AxiosResponse<RecommendEntriesResponse[]>) => {
            setTopEntries(value.data);
        });

        if (language === "ja") {
            sendToGoogleAnalytics("/");
        } else {
            sendToGoogleAnalytics("/" + language);
        }
    }, []);

    return <div id="home">
        <form id="search" onSubmit={(e) => {
            e.preventDefault();
            if (queryRef.current === null) {
                return;
            }

            let parameters = {
                target: "recipe",
                keyword: queryRef.current.value,
            };
            window.location.href = "/" + language + "/search?" + new URLSearchParams(parameters).toString();
        }} >
            <input id="search-text" type="text" ref={queryRef} />
            <input id="search-button" type="submit" value={describeWordSearch()} />
        </form>
        <div id="recommendation">
            <div className="headline">{describePickUp()}</div>
            <Grid container spacing={3} justifyContent="space-between" alignItems="stretch">
                {topEntries.map((entry, i_entry) => {
                    return <Grid key={i_entry} item xs={12} sm={6} md={4} lg={3}>
                        <Card className="recommendation-entry">
                            <a href={"/" + language + "/recipes/" + entry.id}>
                                <img src={entry.image ? "/img/recipes/" + entry.id[0] + "/" + entry.id + "/" + entry.image : ""} />
                                <br />
                                <span>{entry.name}</span>
                            </a>
                        </Card>
                    </Grid>;
                })}
            </Grid>
        </div>
    </div>;
};
