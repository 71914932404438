import { VFC, useState, useEffect } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import { useCookies } from "react-cookie";
import { sendToGoogleAnalytics } from './Analytics';
import { Helmet } from 'react-helmet-async';
import './Foodstuff.scss';


type FoodstuffResponse = {
    id: string,
    name: string,
    sub_names: string[],
    images: string[],
    documents: FoodstuffDocumentResponse[],
};

type FoodstuffDocumentResponse = {
    category: string,
    title: string,
    value: string,
    images: string[],
};

type FoodstuffResponseError = {
    message: string,
};


type RouteProps = {
    language: string,
};


export const Foodstuff: VFC<RouteProps> = (props: RouteProps) => {
    const params = useParams();
    const foodstuff_id = params.foodstuff_id ?? "";

    const [cookies, setCookie, removeCookie] = useCookies(["language"]);
    // const [language, setLanguage] = useState<string>(cookies.language ?? "ja");
    const language = props.language;
    const [loadCondition, setLoadCondition] = useState<number>(0);

    const url = process.env.REACT_APP_API_URL + "/api/" + language + "/foodstuffs/" + foodstuff_id;

    const [foodstuffName, setFoodstuffName] = useState<string>("");
    const [foodstuffSubNames, setFoodstuffSubNames] = useState<string[]>([]);
    const [foodstuffImages, setFoodstuffImages] = useState<string[]>([]);
    const [foodstuffDocuments, setFoodstuffDocuments] = useState<FoodstuffDocumentResponse[]>([]);

    function describeHeadline(x: string) {
        if (language === "ja") {
            return x;
        }

        if (x === "読み込み中") {
            return "Loading...";
        } else if (x === "読み込み失敗") {
            return "Load Failed.";
        }

        return "";
    }

    function describeSearchRecipeOfItsFoodstuff(x: string) {
        if (language === "ja") {
            return x + "のレシピを検索";
        } else {
            return "Search recipe using " + x + ".";
        }
    }

    useEffect(() => {
        axios.get<FoodstuffResponse>(url).then((value: AxiosResponse<FoodstuffResponse>) => {
            setFoodstuffName(value.data.name);
            setFoodstuffSubNames(value.data.sub_names);
            setFoodstuffImages(value.data.images);
            setFoodstuffDocuments(value.data.documents);

            setLoadCondition(1);

            sendToGoogleAnalytics("/" + language + "/foodstuffs/" + foodstuff_id);
        }).catch((e: AxiosError<FoodstuffResponseError>) => {
            setLoadCondition(-1);
        });
    }, []);


    if (loadCondition === 0) {
        return <div id="foodstuff">{describeHeadline("読み込み中")}</div>;
    } else if (loadCondition === -1) {
        return <div id="foodstuff">{describeHeadline("読み込み失敗")}</div>;
    }

    return <>
        <Helmet>
            <title>{(language === "ja" ? "食材: " : "foodstuff: ") + foodstuffName} - {language === "ja" ? "ぼくレピ" : "BokuRepe (My Recipes)"}</title>
            <link rel="canonical" href={process.env.REACT_APP_APP_URL + "/" + language + "/foodstuffs/" + foodstuff_id} />
        </Helmet>
        <div id="foodstuff">
            <div id="title">
                <h1>{foodstuffName}</h1>
                <div className="sub_names">{foodstuffSubNames.join(", ")}</div>
            </div>
            <div>
                <select
                    defaultValue={language}
                    onChange={(e) => {
                    window.location.href = "/" + e.target.value + "/foodstuffs/" + foodstuff_id;
                }}>
                    <option value="ja">日本語</option>
                    <option value="en">English</option>
                </select>
            </div>
            <div id="gallery">
                {
                    foodstuffImages.length > 0 ?
                        <ImageGallery items={
                            foodstuffImages.map((name, i) => { return { original: '/img/foodstuffs/' + name } })
                        } /> :
                        <img className="noimage" src={"/img/sys/noimage.png"} />
                }
            </div>
            <div id="link">
                <a className="link" href={"/" + language + "/search?foodstuff=" + foodstuff_id}>{describeSearchRecipeOfItsFoodstuff(foodstuffName)}</a>
            </div>
            <FoodstuffCategory
                headline={describeHeadline("概要")}
                documents={foodstuffDocuments.filter((doc) => doc.category === "description")}
            />
        </div>
    </>;
};

type FoodstuffCategoryProps = {
    headline: string,
    documents: FoodstuffDocumentResponse[],
};

const FoodstuffCategory = (props: FoodstuffCategoryProps) => {
    return <div>
        {props.documents.map((doc, i_doc) =>
            <div key={i_doc}>
                <div className="document-title">{doc.title}</div>
                <div className="document-value">{doc.value}</div>
            </div>
        )}
    </div>;
};
