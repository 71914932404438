import { useState, useEffect } from "react";
import axios, { AxiosResponse, AxiosError } from 'axios';
import ImageGallery from 'react-image-gallery';
import { Grid } from '@material-ui/core';
import "./Gallery.scss";


type GetFoodstuffWithImageResponseItem = {
    id: string,
    name: string,
    images: string[],
};


const url = process.env.REACT_APP_API_URL + "/editor";


export const EditorGallery = () => {

    const [foodstuffItems, setFoodstuffItems] = useState<GetFoodstuffWithImageResponseItem[]>([]);

    useEffect(() => {
        axios.get<GetFoodstuffWithImageResponseItem[]>(url + "/ja/gallery/foodstuffs")
            .then((value: AxiosResponse<GetFoodstuffWithImageResponseItem[]>) => {
                setFoodstuffItems(value.data);
            });
    }, []);

    return <div id="editor-gallery">
        <Grid container spacing={3} justifyContent="space-between" alignItems="stretch">
            {foodstuffItems.map((item, i_item) => {
                return <Grid className="gallery-item" item key={i_item} xs={12} sm={6} lg={3}>
                    {item.images.length > 0 ?
                        <ImageGallery items={item.images.map((x) => {
                            return {
                                original: "/img/foodstuffs/" + x,
                                description: x,
                            };
                        })} /> :
                        <img className="noimage" src={"/img/sys/noimage.png"} />
                    }
                    <p>{item.name}</p>
                </Grid>;
            })}
        </Grid>
    </div>;
};
