import { VFC, useState, useEffect } from 'react';
import { useCookies } from "react-cookie";
import './Header.scss';


type HeaderProps = {
    language: string,
};

function get_url(language: string) {
    if (language === "ja") {
        return "/";
    } else {
        return "/" + language;
    }
}

function get_description(language: string) {
    if (language === "ja") {
        return "ぼくレピ";
    } else {
        return "Boku-Repe";
    }
}


export const Header: VFC<HeaderProps> = (props: HeaderProps) => {
    return <header>
        <a href={get_url(props.language)}>
            <img src={`${process.env.PUBLIC_URL}/img/head.png`} />
            <span>{get_description(props.language)}</span>
        </a>
    </header>;
};

export default Header;
