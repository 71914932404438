import { VFC, useState, useEffect, useRef } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { Grid } from '@material-ui/core';
import "./Recipe.scss";


type RecipeEditorResponse = {
    meal: number,
    volume: number,
    released: boolean,
    independent: boolean,
    names: NameResponse[],
    descriptions: RecipeDescriptionResponse[],
    images: string[],
    tags: TagResponse[],
    ingredients: IngredientResponse[],
    steps: StepResponse[],
    substitutions: SubstitutionResponse[],
};

type NameResponse = {
    value: string,
    language: string,
};

type RecipeDescriptionResponse = {
    value: string,
    language: string,
}

type TagResponse = {
    id: string,
    name: string,
};

type IngredientResponse = {
    id: string,
    tag: string,
    name: string,
    link: string,
    quantity: string[],
    optional: boolean,
};

type StepResponse = {
    id: string,
    explanations: StepExplanationResponse[],
    number: number,
    leavable: boolean,
    optional: boolean,
    processes: ProcessResponse[],
};

type StepExplanationResponse = {
    tag: string,
    name: string,
    link: string,
};

type ProcessResponse = {
    id: string,
    arguments: string[],
    argument_count: number,
    descriptions: DescriptionResponse[],
};

type DescriptionResponse = {
    tag: string,
    value: string,
    link?: string, // not used
    reference?: string, // not used
    quantity?: string, // not used
    optional: boolean, // not used
};

type SubstitutionResponse = {
    origins: string[],
    targets: SubstitutionTargetResponse[],
};

type SubstitutionTargetResponse = {
    tag: string,
    target_id: string,
    name: string,
    quantity: string[],
};

type SearchIngredientResponse = {
    tag: string,
    link: string,
    name: string,
};

type SearchProcessResponse = {
    process_id: string,
    argument_count: number,
    description: string,
};

type SearchTagResponse = {
    id: string,
    name: string,
};

type SaveRecipeResponse = {
    message: string,
};


type ResponseError = {
    message: string,
};


const url = process.env.REACT_APP_API_URL + "/editor";

function top_of_quantity(quant: string[]) {
    if (quant.length === 1) {
        return 0;
    } else {
        return Number(quant[0]);
    }
}

function bottom_of_quantity(quant: string[]) {
    if (quant.length < 3) {
        return 1;
    } else {
        return Number(quant[1]);
    }
}

function unit_of_quantity(quant: string[]) {
    return quant[quant.length - 1];
}

const units = [
    ["ml", "mL"],
    ["L", "L"],
    ["g", "g"],
    ["kg", "kg"],
    ["tsp", "小さじ"],
    ["tbsp", "大さじ"],
    ["glv", "片"],
    ["pc", "個"],
    ["sht", "枚"],
    ["bt", "本"],
    ["hk", "匹"],
    ["mod", "適量"],
];

export const EditorRecipe = () => {
    const params = useParams();
    const recipe_id = params.recipe_id ?? "";

    // const [cookies, setCookie, removeCookie] = useCookies(["language"]);
    // const [language, setLanguage] = useState<string>(cookies.language ?? "ja");
    const [loadCondition, setLoadCondition] = useState<number>(0);

    // 基本的な情報
    const [meal, setMeal] = useState<number>(0);
    const [volume, setVolume] = useState<number>(0);
    const [released, setReleased] = useState<boolean>(false);
    const [independent, setIndependent] = useState<boolean>(true);
    const [names, setNames] = useState<NameResponse[]>([]);
    const [descriptions, setDescriptions] = useState<RecipeDescriptionResponse[]>([]);
    const [images, setImages] = useState<string[]>([]);
    const [tags, setTags] = useState<TagResponse[]>([]);
    const [ingredients, setIngredients] = useState<IngredientResponse[]>([]);
    const [steps, setSteps] = useState<StepResponse[]>([]);
    const [substitutions, setSubstitutions] = useState<SubstitutionResponse[]>([]);

    // 追加候補をリストアップするもの
    const [ingredientCandidates, setIngredientCandidates] = useState<SearchIngredientResponse[]>([]);
    const [tagCandidates, setTagCandidates] = useState<SearchTagResponse[]>([]);

    //
    // 材料を追加する処理
    //
    const addIngredientInput = useRef<HTMLInputElement>(null);
    const [addIngredientInputValue, setAddIngredientInputValue] = useState<string>("");
    const handleAddIngredientInput = () => {
        if (addIngredientInput.current === null) { return; }
        setAddIngredientInputValue(addIngredientInput.current.value);
        let query = addIngredientInput.current.value.split(" ").filter((x) => x !== "");
        if (query.length === 0) {
            setIngredientCandidates([]);
            return;
        }
        axios.post<SearchIngredientResponse[]>(url + "/search/ingredient", {
            query: query,
            language: "ja",
        }).then((value: AxiosResponse<SearchIngredientResponse[]>) => {
            setIngredientCandidates(value.data.slice(0, 24));
        }).catch((e: AxiosError<ResponseError>) => {
        });
    };

    //
    // タグを追加する処理
    //
    const addTagInput = useRef<HTMLInputElement>(null);
    const [addTagInputValue, setAddTagInputValue] = useState<string>("");
    const handleAddTagInput = () => {
        if (addTagInput.current === null) { return; }
        setAddTagInputValue(addTagInput.current.value);
        let xs = addTagInput.current.value.split(" ").filter((x) => x.length > 0);
        if (xs.length === 0) {
            setTagCandidates([]);
            return;
        }
        axios.post<SearchTagResponse[]>(url + "/search/tag", {
            query: xs,
            language: "ja",
        }).then((value: AxiosResponse<SearchTagResponse[]>) => {
            setTagCandidates(value.data.slice(0, 16));
        }).catch((e: AxiosError) => {});
    };


    function addName() {
        let name: NameResponse = {
            value: "",
            language: "en",
        };
        setNames([...names, name]);
    }

    function updateNameValue(index: number, value: string) {
        setNames(names.map((x, i_x) => {
            if (i_x === index) {
                return { value: value, language: x.language };
            } else {
                return x;
            }
        }));
    }

    function updateNameLanguage(index: number, lang: string) {
        setNames(names.map((x, i_x) => {
            if (i_x === index) {
                return { value: x.value, language: lang };
            } else {
                return x;
            }
        }));
    }

    function deleteName(index: number) {
        setNames(names.filter((x, i_x) => i_x !== index));
    }

    function addDescription() {
        let desc: RecipeDescriptionResponse = {
            value: "",
            language: "en"
        };
        setDescriptions([...descriptions, desc]);
    }

    function updateDescriptionLanguage(index: number, lang: string) {
        setDescriptions(descriptions.map((x, i_x) => {
            if (i_x === index) {
                return { value: x.value, language: lang };
            } else {
                return x;
            }
        }));
    }

    function updateDescriptionValue(index: number, value: string) {
        setDescriptions(descriptions.map((x, i_x) => {
            if (i_x === index) {
                return { value: value, language: x.language };
            } else {
                return x;
            }
        }));
    }

    function deleteDescription(index: number) {
        setDescriptions(descriptions.filter((x, i_x) => i_x !== index));
    }

    function getIngredients() {
        return ingredients;
    }

    function addIngredient(link: string, tag: string, name: string) {
        let ing_ids = ingredients.map((ing) => ing.id);

        const length = 3;
        const cans = "0123456789abcdef";

        let nid = "";

        while (true) {
            nid = link + "_" + [...Array(length)].map(() => cans[Math.floor(Math.random() * cans.length)]).join("");
            if (ing_ids.every((ig) => ig !== nid)) {
                break;
            }
        }

        let ingredient: IngredientResponse = {
            id: nid,
            tag: tag,
            link: link,
            name: name,
            quantity: ["1", "1", "pc"],
            optional: false,
        };
        setIngredients([...ingredients, ingredient]);
    }

    function updateIngredientQuantity(ing_id: string, top: number, bottom: number, unit: string) {
        setIngredients(ingredients.map((ing) => {
            if (ing.id === ing_id) {
                ing.quantity = [String(top), String(bottom), unit];
            }
            return ing;
        }))
    }

    function setIngredientOptional(ing_id: string, swt: boolean) {
        setIngredients(ingredients.map((ing) => {
            if (ing.id === ing_id) {
                ing.optional = swt;
            }
            return ing;
        }));
    }

    function deleteIngredient(ing_id: string) {
        setIngredients(ingredients.filter((x) => x.id !== ing_id));
    }

    function getSteps() {
        return steps;
    }

    function addStep() {
        function allocateId(ids: string[]) {
            const length = 4;
            const cans = "0123456789abcdef";

            while (true) {
                let nid = "stp_" + [...Array(length)].map(() => cans[Math.floor(Math.random() * cans.length)]).join("");
                if (ids.every((it) => it !== nid)) {
                    return nid;
                }
            }
        }

        const newStep: StepResponse  = {
            id: allocateId(steps.map((stp) => stp.id)),
            number: -1,
            explanations: [],
            leavable: false,
            optional: false,
            processes: [],
        };

        setSteps([...steps, newStep]);
    }

    function removeStep(step_id: string) {
        setSteps(steps.filter((step) => step.id !== step_id));
    }

    function addStepExplanation(step_id: string, tag: string, name: string, link: string) {
        setSteps(steps.map((step) => {
            if (step.id === step_id) {
                step.explanations.push({
                    tag: tag,
                    name: name,
                    link: link,
                });
            }
            return step;
        }));
    }

    function deleteStepExplanation(step_id: string, i_explanation: number) {
        setSteps(steps.map((step) => {
            if (step.id === step_id) {
                step.explanations = step.explanations.filter((_, i_ex) => i_ex !== i_explanation);
            }
            return step;
        }));
    }

    function setStepLeavable(step_id: string, leavable: boolean) {
        setSteps(steps.map((step) => {
            if (step.id === step_id) {
                step.leavable = leavable;
            }
            return step;
        }));
    }

    function setStepOptional(step_id: string, optional: boolean) {
        setSteps(steps.map((step) => {
            if (step.id === step_id) {
                step.optional = optional;
            }
            return step;
        }))
    }

    function moveStep(step_id: string, up: boolean) {
        const idxs = steps.map((step, i_step) => {
            if (step.id === step_id) {
                return i_step;
            } else {
                return -1;
            }
        });

        const index = Math.max(...idxs);

        if (up && index > 0) {
            setSteps(
                steps.slice(0, index - 1)
                    .concat([steps[index]])
                    .concat([steps[index - 1]])
                    .concat(steps.slice(index + 1))
            );
        } else if (! up && index < steps.length - 1) {
            setSteps(
                steps.slice(0, index)
                    .concat([steps[index + 1]])
                    .concat([steps[index]])
                    .concat(steps.slice(index + 2))
            );
        }
    }

    function addProcess(step_id: string, process_id: string, arg_count: number) {
        axios.get<ProcessResponse>(url + "/ja/processes/" + process_id + "/" + arg_count).then((value: AxiosResponse<ProcessResponse>) => {
            setSteps(steps.map((step) => {
                if (step.id === step_id) {
                    step.processes.push(value.data);
                    return step;
                } else {
                    return step;
                }
            }));
        }).catch((e: AxiosError<ResponseError>) => {
            alert("addProcess");
        });
    }

    function deleteProcess(step_id: string, i_process: number) {
        setSteps(steps.map((step) => {
            if (step.id === step_id) {
                step.processes = step.processes.slice(0, i_process).concat(step.processes.slice(i_process + 1));
            }
            return step;
        }));
    }

    function moveProcess(step_id: string, i_process: number, up: boolean) {
        setSteps(steps.map((step) => {
            if (step.id === step_id) {
                if (up && i_process !== 0) {
                    step.processes = step.processes.slice(0, i_process - 1)
                        .concat([step.processes[i_process]])
                        .concat([step.processes[i_process - 1]])
                        .concat(step.processes.slice(i_process + 1));
                } else if (! up && i_process !== step.processes.length - 1) {
                    step.processes = step.processes.slice(0, i_process)
                        .concat([step.processes[i_process + 1]])
                        .concat([step.processes[i_process]])
                        .concat(step.processes.slice(i_process + 2));
                }
            }
            return step;
        }));
    }

    function addProcessArgument(step_id: string, i_process: number, ing_id: string) {
        setSteps(steps.map((step) => {
            if (step.id === step_id) {
                step.processes.map((proc, i_proc) => {
                    if (i_proc === i_process) {
                        proc.arguments.push(ing_id);
                    }
                });
            }
            return step;
        }));
    }

    function deleteProcessArgument(step_id: string, i_process: number, i_argument: number) {
        setSteps(steps.map((step) => {
            if (step.id === step_id) {
                step.processes.map((proc, i_proc) => {
                    if (i_proc === i_process) {
                        proc.arguments = proc.arguments.slice(0, i_argument).concat(proc.arguments.slice(i_argument + 1));
                    }
                });
            }
            return step;
        }));
    }

    function addSubstitution() {
        let substitution = {
            origins: [],
            targets: [],
        };
        setSubstitutions([...substitutions, substitution]);
    }

    function deleteSubstitution(idx_sub: number) {
        setSubstitutions(substitutions.slice(0, idx_sub).concat(substitutions.slice(idx_sub + 1)));
    }

    function addSubstitutionOrigin(idx_sub: number, ing_id: string) {
        setSubstitutions(substitutions.map((sub, i_sub) => {
            if (idx_sub === i_sub) {
                sub.origins.push(ing_id);
            }
            return sub;
        }));
    }

    function deleteSubstitutionOrigin(idx_sub: number, idx_origin: number) {
        setSubstitutions(substitutions.map((sub, i_sub) => {
            if (idx_sub === i_sub) {
                sub.origins = sub.origins.filter((ori, i_ori) => i_ori !== idx_origin);
            }
            return sub;
        }));
    }

    function addSubstitutionTarget(idx_sub: number, link: string, tag: string, name: string) {
        setSubstitutions(substitutions.map((sub, i_sub) => {
            if (idx_sub === i_sub) {
                sub.targets.push({
                    target_id: link,
                    tag: tag,
                    name: name,
                    quantity: ["mod"]
                });
            }
            return sub;
        }))
    }

    function deleteSubstitutionTarget(idx_sub: number, idx_target: number) {
        setSubstitutions(substitutions.map((sub, i_sub) => {
            if (i_sub === idx_sub) {
                sub.targets = sub.targets.filter((tar, i_tar) => i_tar !== idx_target);
            }
            return sub;
        }));
    }

    function updateSubstitutionTargetQuantity(idx_sub: number, idx_tar: number, top: number, bottom: number, unit: string) {
        setSubstitutions(substitutions.map((sub, i_sub) => {
            if (i_sub === idx_sub) {
                sub.targets.map((tar, i_tar) => {
                    if (i_tar === idx_tar) {
                        tar.quantity = [String(top), String(bottom), unit];
                    }
                })
            }
            return sub;
        }));
    }

    useEffect(() => {
        axios.get<RecipeEditorResponse>(url + "/ja/recipes/" + recipe_id).then((value: AxiosResponse<RecipeEditorResponse>) => {
            setMeal(value.data.meal);
            setVolume(value.data.volume);
            setReleased(value.data.released);
            setIndependent(value.data.independent);
            setNames(value.data.names);
            setDescriptions(value.data.descriptions);
            setTags(value.data.tags);
            setImages(value.data.images);
            setIngredients(value.data.ingredients);
            setSteps(value.data.steps);
            setSubstitutions(value.data.substitutions);
            setLoadCondition(1);
        }).catch((e: AxiosError<ResponseError>) => {
            setLoadCondition(-1);
        });
    }, []);

    if (process.env.REACT_APP_APP_ENV === "prod") {
        return <div>Not Found</div>;
    }

    if (loadCondition === 0) {
        return <div>ロード中</div>;
    } else if (loadCondition === -1) {
        return <div>ロードエラー</div>;
    }

    return <div id="recipe-editor">
        <div id="save-transition">
            <div>
                <button id="save" type="button" onClick={(e) => {
                    e.currentTarget.textContent = "Saving...";
                    e.currentTarget.disabled = true;

                    axios.post(url + "/save/recipe", {
                        id: recipe_id,
                        released: released,
                        independent: independent,
                        meal: meal,
                        volume: volume,
                        names: names,
                        descriptions: descriptions,
                        tags: tags.map((t) => t.id),
                        ingredients: ingredients.map((ing) => {
                            return {
                                id: ing.id,
                                tag: ing.tag,
                                link: ing.link,
                                quantity: ing.quantity,
                                optional: ing.optional,
                            };
                        }),
                        steps: steps.map((step) => {
                            return {
                                id: step.id,
                                leavable: step.leavable,
                                optional: step.optional,
                                explanations: step.explanations.map((ex) => {
                                    return {
                                        tag: ex.tag,
                                        link: ex.link,
                                    };
                                }),
                                processes: step.processes.map((proc) => {
                                    return {
                                        id: proc.id,
                                        arguments: proc.arguments,
                                    };
                                }),
                            };
                        }),
                        substitutions: substitutions.map((sub) => {
                            return {
                                origins: sub.origins,
                                targets: sub.targets.map((tar) => {
                                    return {
                                        tag: tar.tag,
                                        link: tar.target_id,
                                        quantity: tar.quantity,
                                    };
                                }),
                            };
                        }),
                    }).then((value: AxiosResponse<SaveRecipeResponse>) => {
                        let elem = document.getElementById("save");
                        if (elem === null) {
                            return;
                        }
                        elem.textContent = "Saved. Reloading...";
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    }).catch().finally(() => {
                        let elem = document.getElementById("save");
                        if (elem === null) {
                            return;
                        }
                        (elem as HTMLButtonElement).disabled = false;
                    });
                }}>Save and Refresh</button>
            </div>
            <div id="transition">
                <a href={"/editor"}>← Home</a>
                <a href={"/ja/recipes/" + recipe_id}>View →</a>
            </div>
        </div>
        <div id="recipe-images">
            {images.map((name, i_img) =>
                <div key={i_img} className="recipe-image">
                    <img className="recipe-image-content"
                        src={"/img/recipes/" + (recipe_id ?? "a")[0] + "/" + recipe_id + "/" + name}
                    />
                    <div className="recipe-image-delete"
                        onClick={() => {
                            if (recipe_id === undefined) {
                                return;
                            }

                            axios.post(url + "/delete/image", {
                                recipe_id: recipe_id,
                                name: name,
                            }).then(() => {
                                window.location.reload();
                            });
                        }}
                    >✕</div>
                    <div>{name}</div>
                </div>
            )}
            <input type="file"
                accept="image/*,.png,.jpg,.jpeg"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (! e.target.files || recipe_id === undefined) {
                        return;
                    }

                    let data = new FormData();
                    data.append("recipe_id", recipe_id);

                    let files = e.target.files;
                    for (let i = 0; i < files.length; i++) {
                        let file = files.item(i);
                        if (file === null) {
                            continue;
                        }
                        data.append("images", file);
                    }

                    axios.post(url + "/save/image", data, {
                        headers: { "content-type": "multipart/form-data" },
                    }).finally(() => {
                        window.location.reload();
                    });
                }}
            />
        </div>
        <div id="recipe-properties">
            <span className="recipe-meal">
                <span>meal</span>
                <input type="number" min={1} max={36} defaultValue={meal} onChange={(e) => {
                    setMeal(Number(e.target.value));
                }} />
            </span>
            <span className="recipe-volume">
                <span>volume</span>
                <input type="number" min={1} max={100} defaultValue={volume} onChange={(e) => {
                    setVolume(Number(e.target.value));
                }} />
            </span>
            <span className={"recipe-released" + (released ? " on" : "")} onClick={() => {
                setReleased(! released);
            }}>公開状態: {released ? "公開" : "非公開"}</span>
            <span className={"recipe-independent" + (independent ? " on" : "")} onClick={() => {
                setIndependent(! independent);
            }}>一品料理: {independent ? "true" : "false"}</span>
        </div>
        <div id="recipe-names">
            <div className="headline">名前</div>
            {names.map((nm, i_name) =>
                <NameRow
                    key={i_name}
                    value={nm.value}
                    language={nm.language}
                    index={i_name}
                    updateNameValue={updateNameValue}
                    updateNameLanguage={updateNameLanguage}
                    deleteName={deleteName}
                />
            )}
            <div>
                <button type="button" onClick={() => addName()}>New</button>
            </div>
        </div>
        <div id="recipe-descriptions">
            <div className="headline">説明</div>
            {descriptions.map((ds, i_description) =>
                <DescriptionRow
                    key={i_description}
                    value={ds.value}
                    language={ds.language}
                    index={i_description}
                    updateDescriptionValue={updateDescriptionValue}
                    updateDescriptionLanguage={updateDescriptionLanguage}
                    deleteDescription={deleteDescription}
                />
            )}
            <div>
                <button type="button" onClick={() => addDescription()}>New</button>
            </div>
        </div>
        <div id="recipe-tags">
            <div className="headline">タグ</div>
            <div id="recipe-tags-entries">
                {tags.map((tag, i_tag) =>
                    <span key={i_tag} className="recipe-tag">
                        <span className="recipe-tag-id">{tag.id}</span>
                        <span className="recipe-tag-name">{tag.name}</span>
                        <span onClick={() => {
                            setTags(tags.filter((_, i_t) => i_t !== i_tag));
                        }}>x</span>
                    </span>
                )}
            </div>
            <div id="recipe-tags-candidates">
                {tagCandidates.map((tag, i_tag) =>
                    <span key={i_tag} className="recipe-tag-candidate" onClick={(e) => {
                        let cand: SearchTagResponse = {
                            id: e.currentTarget.getElementsByClassName("recipe-tag-candidate-id")[0].textContent ?? "",
                            name: e.currentTarget.getElementsByClassName("recipe-tag-candidate-name")[0].textContent ?? "",
                        };
                        setTags([...tags, cand]);
                        setTagCandidates([]);
                        setAddTagInputValue("");
                    }}>
                        <span className="recipe-tag-candidate-id">{tag.id}</span>
                        <span className="recipe-tag-candidate-name">{tag.name}</span>
                    </span>
                )}
            </div>
            <input type="text" ref={addTagInput} value={addTagInputValue} onChange={handleAddTagInput} />
        </div>
        <div id="recipe-ingredients">
            <div className="headline">材料</div>
            {ingredients.map((ing, i_ing) =>
                <IngredientRow
                    key={i_ing}
                    id={ing.id}
                    tag={ing.tag}
                    link={ing.link}
                    name={ing.name}
                    quantity={ing.quantity}
                    optional={ing.optional}
                    updateIngredientQuantity={updateIngredientQuantity}
                    setIngredientOptional={setIngredientOptional}
                    deleteIngredient={deleteIngredient}
                />
            )}
            <div className="add-ingredient">
                <input id="add-ingredient-input" type="text" ref={addIngredientInput} value={addIngredientInputValue} onChange={handleAddIngredientInput} />
                <div>
                    {ingredientCandidates.map((ing, i_ing) =>
                        <div key={i_ing} className="search-ingredient" onClick={(e) => {
                            let link = e.currentTarget.getElementsByClassName("search-ingredient-link")[0].textContent ?? "";
                            let tag = e.currentTarget.getElementsByClassName("search-ingredient-tag")[0].textContent ?? "";
                            let name = e.currentTarget.getElementsByClassName("search-ingredient-name")[0].textContent ?? "";
                            addIngredient(link, tag, name);
                            setAddIngredientInputValue("");
                            setIngredientCandidates([]);
                        }}>
                            <span className="search-ingredient-link">{ing.link}</span>
                            <span className="search-ingredient-tag">{ing.tag}</span>
                            <span className="search-ingredient-tag-description">{ing.tag === "foodstuff" ? "食材" : "レシピ"}</span>
                            <span className="search-ingredient-name">{ing.name}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
        <div id="recipe-steps">
            <div className="headline">調理手順</div>
            {steps.length > 0 ?
                <Grid container spacing={3} justifyContent="space-between" alignItems="stretch">
                    {steps.map((step, i_step) =>
                        <Grid item key={i_step} xs={12} lg={6}>
                            <Step
                                key={i_step}
                                id={step.id}
                                number={step.number}
                                explanations={step.explanations}
                                leavable={step.leavable}
                                optional={step.optional}
                                processes={step.processes}
                                setStepLeavable={setStepLeavable}
                                setStepOptional={setStepOptional}
                                getSteps={getSteps}
                                removeStep={removeStep}
                                moveStep={moveStep}
                                addStepExplanation={addStepExplanation}
                                deleteStepExplanation={deleteStepExplanation}
                                addProcess={addProcess}
                                deleteProcess={deleteProcess}
                                moveProcess={moveProcess}
                                getIngredients={getIngredients}
                                addProcessArgument={addProcessArgument}
                                deleteProcessArgument={deleteProcessArgument}
                            />
                        </Grid>
                    )}
                </Grid>
                : <div></div>
            }
            <button type="button" onClick={() => addStep()}>New</button>
        </div>
        <div id="recipe-substitutions">
            <div className="headline">代用</div>
            {substitutions.map((sub, i_sub) =>
                <Substitution
                    key={i_sub}
                    index={i_sub}
                    origins={sub.origins}
                    targets={sub.targets}
                    getIngredients={getIngredients}
                    deleteSubstitution={deleteSubstitution}
                    addSubstitutionOrigin={addSubstitutionOrigin}
                    deleteSubstitutionOrigin={deleteSubstitutionOrigin}
                    addSubstitutionTarget={addSubstitutionTarget}
                    deleteSubstitutionTarget={deleteSubstitutionTarget}
                    updateSubstitutionTargetQuantity={updateSubstitutionTargetQuantity}
                />
            )}
            <button type="button" onClick={() => addSubstitution()}>New</button>
        </div>
    </div>;
};

type NameRowProps = {
    value: string,
    language: string,
    index: number,
    updateNameValue: (index: number, value: string) => void,
    updateNameLanguage: (index: number, lang: string) => void,
    deleteName: (index: number) => void,
};

const NameRow = (props: NameRowProps) => {
    const langs = [
        ["ja", "日本語"],
        ["en", "English"],
        ["fr", "Français"],
        ["it", "Italiano"],
    ];

    return <div className="recipe-name">
        <input type="text" className="name-value" value={props.value} onChange={(e) => props.updateNameValue(props.index, e.target.value)} />
        <select defaultValue={props.language} className="name-language" onChange={(e) => props.updateNameLanguage(props.index, e.target.value)}>
            {langs.map((xs, i_x) =>
                <option key={i_x} value={xs[0]}>{xs[1]}</option>
            )}
        </select>
        <span onClick={() => props.deleteName(props.index)}>×</span>
    </div>;
};

type DescriptionRowProps = {
    value: string,
    language: string,
    index: number,
    updateDescriptionValue: (index: number, value: string) => void,
    updateDescriptionLanguage: (index: number, lang: string) => void,
    deleteDescription: (index: number) => void,
};

const DescriptionRow = (props: DescriptionRowProps) => {
    const langs = [
        ["ja", "日本語"],
        ["en", "English"],
        ["fr", "Français"],
        ["it", "Italiano"],
    ];

    return <div className="recipe-description">
        <textarea className="description-value" value={props.value} onChange={(e) => props.updateDescriptionValue(props.index, e.target.value)} />
        <select defaultValue={props.language} className="description-language" onChange={(e) => props.updateDescriptionLanguage(props.index, e.target.value)}>
            {langs.map((xs, i_x) =>
                <option key={i_x} value={xs[0]}>{xs[1]}</option>
            )}
        </select>
        <span onClick={() => props.deleteDescription(props.index)}>×</span>
    </div>
}

type IngredientRowProps = {
    id: string,
    tag: string,
    link: string,
    name: string,
    quantity: string[],
    optional: boolean,
    updateIngredientQuantity: (ing_id: string, top: number, bottom: number, unit: string) => void,
    setIngredientOptional: (ing_id: string, swt: boolean) => void,
    deleteIngredient: (iid: string) => void,
};

const IngredientRow = (props: IngredientRowProps) => {
    const [quantityTop, setQuantityTop] = useState<number>(top_of_quantity(props.quantity));
    const [quantityBottom, setQuantityBottom] = useState<number>(bottom_of_quantity(props.quantity));
    const [quantityUnit, setQuantityUnit] = useState<string>(unit_of_quantity(props.quantity));

    return <div className="ingredient">
        <span className="ingredient-id">{props.id}</span>
        <span className="ingredient-tag">{props.tag === "foodstuff" ? "食材" : "レシピ"}</span>
        <span className="ingredient-name">{props.name}</span>
        <span className="ingredient-quantity">
            <input type="number" className="ingredient-quantity-top"
                min={0} max={1000000}
                defaultValue={top_of_quantity(props.quantity)}
                onChange={(e) => {
                    let value = Number(e.target.value);
                    setQuantityTop(value);
                    // quantityTopを使うとズレる
                    props.updateIngredientQuantity(props.id, value, quantityBottom, quantityUnit);
                }}
            />/
            <input type="number" className="ingredient-quantity-bottom"
                min={1} max={1000000}
                defaultValue={bottom_of_quantity(props.quantity)}
                onChange={(e) => {
                    let value = Number(e.target.value);
                    setQuantityBottom(value);
                    props.updateIngredientQuantity(props.id, quantityTop, value, quantityUnit);
                }}
            />
            <select className="ingredient-quantity-unit"
                defaultValue={unit_of_quantity(props.quantity)}
                onChange={(e) => {
                    let value = e.target.value;
                    setQuantityUnit(value);
                    props.updateIngredientQuantity(props.id, quantityTop, quantityBottom, value);
                }}
            >
                <option value=""></option>
                {units.map((unit, i_unit) =>
                    <option key={i_unit} value={unit[0]}>{unit[1]}</option>
                )}
            </select>
        </span>
        <span className={"ingredient-optional" + (props.optional ? " on" : "")} onClick={() => {
            props.setIngredientOptional(props.id, ! props.optional);
        }}>optional: {props.optional ? "true" : "false"}</span>
        <span className="ingredient-delete" onClick={() => {
            props.deleteIngredient(props.id);
        }}>✕</span>
    </div>;
};


type StepProps = {
    id: string,
    number: number,
    explanations: StepExplanationResponse[],
    leavable: boolean,
    optional: boolean,
    processes: ProcessResponse[],
    setStepLeavable: (step_id: string, leavable: boolean) => void,
    setStepOptional: (step_id: string, optional: boolean) => void,
    getSteps: () => StepResponse[],
    removeStep: (step_id: string) => void,
    moveStep: (step_id: string, up: boolean) => void,
    addStepExplanation: (step_id: string, tag: string, name: string, link: string) => void,
    deleteStepExplanation: (step_id: string, i_explanation: number) => void,
    addProcess: (step_id: string, process_id: string, arg_count: number) => void,
    deleteProcess: (step_id: string, i_process: number) => void,
    moveProcess: (step_id: string, i_process: number, up: boolean) => void,
    getIngredients: () => IngredientResponse[],
    addProcessArgument: (step_id: string, i_process: number, ingredient_id: string) => void,
    deleteProcessArgument: (step_id: string, i_process: number, i_argument: number) => void,
};

const Step = (props: StepProps) => {
    const [procs, setProcs] = useState<SearchProcessResponse[]>([]);
    const [explanations, setExplanations] = useState<SearchIngredientResponse[]>([]);

    // step explanations 操作
    const addExplanationInput = useRef<HTMLInputElement>(null);
    const [addExplanationInputValue, setAddExplanationInputValue] = useState<string>("");
    const handleAddExplanationInput = () => {
        if (addExplanationInput.current === null) { return; }
        setAddExplanationInputValue(addExplanationInput.current.value);
        let query = addExplanationInput.current.value.split(" ").filter((x) => x !== "");
        if (query.length === 0) {
            setExplanations([]);
            return;
        }

        axios.post<SearchIngredientResponse[]>(url + "/search/ingredient", {
            query: query,
            language: "ja",
        }).then((value: AxiosResponse<SearchIngredientResponse[]>) => {
            setExplanations(value.data.slice(0, 9));
        }).catch((e: AxiosError<ResponseError>) => {
        });
    };

    // add process 操作
    const addProcessInput = useRef<HTMLInputElement>(null);
    const [addProcessInputValue, setAddProcessInputValue] = useState<string>("");
    const handleAddProcessInput = () => {
        if (addProcessInput.current === null) { return; }
        setAddProcessInputValue(addProcessInput.current.value);
        let query = addProcessInput.current.value.split(" ").filter((x) => x !== "");
        if (query.length === 0) {
            setProcs([]);
            return;
        }
        axios.post<SearchProcessResponse[]>(url + "/search/process", {
            query: query,
            language: "ja",
        }).then((value: AxiosResponse<SearchProcessResponse[]>) => {
            setProcs(value.data.slice(0, 9));
        }).catch((e: AxiosError<ResponseError>) => {
        });
    };

    return <div className={"recipe-step" + (props.number >= 0 ? " p" + (props.number % 8) : " m")}>
        <div className="recipe-step-properties">
            <span className="step-id">id: {props.id}</span>
            <span className="step-number">{props.number}</span>
            <span className={"step-leavable" + (props.leavable ? " on" : "")} onClick={() => {
                props.setStepLeavable(props.id, ! props.leavable);
            }}>leavable: {props.leavable ? "true": "false"}</span>
            <span className={"step-optional" + (props.optional ? " on" : "")} onClick={() => {
                props.setStepOptional(props.id, ! props.optional);
            }}>optional: {props.optional ? "true": "false"}</span>
            <button type="button" onClick={() => props.removeStep(props.id)}>DELETE</button>
            <button type="button" onClick={() => props.moveStep(props.id, true)}>↑</button>
            <button type="button" onClick={() => props.moveStep(props.id, false)}>↓</button>
        </div>
        <div className="recipe-step-explanations">
            <input type="text" ref={addExplanationInput} value={addExplanationInputValue} onChange={handleAddExplanationInput} />
            <span className="explanations">
                {props.explanations.map((ex, i_ex) => {
                    return <span className="explanation" key={i_ex}>
                        <span className="explanation-tag">{ex.tag === "foodstuff" ? "food" : "recipe"}</span>
                        <span className="explanation-name">{ex.name}</span>
                        <span onClick={() => {
                            props.deleteStepExplanation(props.id, i_ex);
                        }}>✕</span>
                    </span>
                })}
            </span>
            <span className="explanation-candidates">
                {explanations.map((ex, i_ex) => {
                    return <span key={i_ex} className="explanation-candidate" onClick={() => {
                        props.addStepExplanation(props.id, ex.tag, ex.name, ex.link);
                        setExplanations([]);
                        setAddExplanationInputValue("");
                    }}>
                        <span className="explanation-candidate-tag">{ex.tag === "foodstuff" ? "food" : "recipe"}</span>
                        <span className="explanation-candidate-name">{ex.name}</span>
                    </span>;
                })}
            </span>
        </div>
        <hr />
        <div className="recipe-processes">
            {props.processes.map((proc, i_proc) =>
                <div key={i_proc} className="recipe-process">
                    <div className="recipe-process-property">
                        <span className="process-id">id: {proc.id}</span>
                        <span className="process-argument-count">args: {proc.argument_count}</span>
                        <button type="button" className="process-delete" onClick={(e) => {
                            props.deleteProcess(props.id, i_proc);
                        }}>DELETE</button>
                        <button type="button" onClick={() => {
                            props.moveProcess(props.id, i_proc, true);
                        }}>↑</button>
                        <button type="button" onClick={() => {
                            props.moveProcess(props.id, i_proc, false);
                        }}>↓</button>
                    </div>
                    <ul>
                        {proc.arguments.map((arg, i_arg) =>
                            <li key={i_arg} className="process-argument">
                                <span>{arg}</span>
                                <span className="process-argument-delete" onClick={() => {
                                    props.deleteProcessArgument(props.id, i_proc, i_arg);
                                }}>✕</span>
                            </li>
                        )}
                    </ul>
                    <div>
                        <select defaultValue="" onChange={(e) => {
                            let value = e.target.value;
                            if (value !== "") {
                                props.addProcessArgument(props.id, i_proc, value);
                            }
                        }}>
                            <option value=""></option>
                            {props.getIngredients().map((ing, i_ing) =>
                                <option key={"ing" + String(i_ing)} value={ing.id}>{ing.id}: {ing.name}</option>
                            )}
                            {props.getSteps().map((step, i_step) =>
                                <option key={"stp" + String(i_step)} value={step.id}>step: {step.id}{ step.explanations.length > 0 ? "(" + step.explanations.map((e) => e.name).join(",") + ")" : ""}</option>
                            )}
                        </select>
                    </div>
                    <div>
                        {proc.descriptions.map((desc, i_desc) => {
                            if (desc.tag === "dummy") {
                                return <span key={i_desc}>[ ]</span>;
                            } else if (desc.tag === "step") {
                                return <span key={i_desc}>[ {desc.value} ]</span>;
                            } else {
                                return <span key={i_desc}>{desc.value}</span>;
                            }
                        })}
                    </div>
                    <hr/>
                </div>
            )}
            <div className="add-process">
                <input type="text" ref={addProcessInput} value={addProcessInputValue} onChange={handleAddProcessInput} />
                <div>
                    {procs.map((pc, i_pc) =>
                        <div key={i_pc} className="search-process" onClick={(e) => {
                            let pid = e.currentTarget.getElementsByClassName("search-process-id")[0].textContent ?? "";
                            let count = e.currentTarget.getElementsByClassName("search-process-argument-count")[0].textContent;
                            props.addProcess(props.id, pid, Number(count));
                            setProcs([]);
                            setAddProcessInputValue("");
                        }}>
                            <span className="search-process-id">{pc.process_id}</span>
                            <span className="search-process-argument-count">{pc.argument_count}</span>
                            <br />
                            <span className="search-process-description">{pc.description}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>;
};

type SubstitutionProps = {
    index: number,
    origins: string[],
    targets: SubstitutionTargetResponse[],
    getIngredients: () => IngredientResponse[],
    deleteSubstitution: (index: number) => void,
    addSubstitutionOrigin: (index: number, ing_id: string) => void,
    deleteSubstitutionOrigin: (index: number, idx_origin: number) => void,
    addSubstitutionTarget: (index: number, link: string, tag: string, name: string) => void,
    deleteSubstitutionTarget: (idx_sub: number, idx_tar: number) => void,
    updateSubstitutionTargetQuantity: (idx_sub: number, idx_tar: number, top: number, bottom: number, unit: string) => void,
};

const Substitution = (props: SubstitutionProps) => {

    const [targetCandidates, setTargetCandidates] = useState<SearchIngredientResponse[]>([]);

    // add substitution target 操作
    const addTargetInput = useRef<HTMLInputElement>(null);
    const [addTargetInputValue, setAddTargetInputValue] = useState<string>("");
    const handleAddTargetInput = () => {
        if (addTargetInput.current === null) { return; }
        setAddTargetInputValue(addTargetInput.current.value);
        let query = addTargetInput.current.value.split(" ").filter((x) => x !== "");
        if (query.length === 0) {
            setTargetCandidates([]);
            return;
        }
        axios.post<SearchIngredientResponse[]>(url + "/search/ingredient", {
            query: query,
            language: "ja",
        }).then((value: AxiosResponse<SearchIngredientResponse[]>) => {
            setTargetCandidates(value.data.slice(0, 24));
        }).catch((e: AxiosError<ResponseError>) => {
        });
    };

    return <div className="substitution">
        <div className="substitution-origins">
            <div><span className="headline">Origin</span></div>
            {props.origins.map((origin, i_origin) =>
                <div key={i_origin} className="substitution-origin">
                    <span className="substitution-origin-id">{origin}</span>
                    <span onClick={() => {
                        props.deleteSubstitutionOrigin(props.index, i_origin);
                    }}>✕</span>
                </div>
            )}
            <select className="add-subsitution-origin" onChange={(e) => {
                if (e.target.value !== "") {
                    props.addSubstitutionOrigin(props.index, e.target.value);
                }
            }}>
                <option value=""></option>
                {props.getIngredients().map((ing, i_ing) =>
                    <option key={i_ing} value={ing.id}>{ing.id} {ing.name}</option>
                )}
            </select>
        </div>
        <div className="substitution-targets">
            <div><span className="headline">Target</span></div>
            {props.targets.map((target, i_target) =>
                <SubstitutionTarget
                    key={i_target}
                    index_substitution={props.index}
                    index_target={i_target}
                    tag={target.tag}
                    target_id={target.target_id}
                    name={target.name}
                    quantity={target.quantity}
                    updateSubstitutionTargetQuantity={props.updateSubstitutionTargetQuantity}
                    deleteSubstitutionTarget={props.deleteSubstitutionTarget}
                />
            )}
            <input type="text" ref={addTargetInput} value={addTargetInputValue} onChange={handleAddTargetInput} />
            <div className="substitution-target-candidates">
                {targetCandidates.map((tg, i_tg) =>
                    <div key={i_tg} className="target-candidate" onClick={(e) => {
                        let link = e.currentTarget.getElementsByClassName("target-candidate-link")[0].textContent ?? "";
                        let tag = e.currentTarget.getElementsByClassName("target-candidate-tag")[0].textContent ?? "";
                        let name = e.currentTarget.getElementsByClassName("target-candidate-name")[0].textContent ?? "";
                        props.addSubstitutionTarget(props.index, link, tag, name);
                        setTargetCandidates([]);
                        setAddTargetInputValue("");
                    }}>
                        <span className="target-candidate-link">{tg.link}</span>
                        <span className="target-candidate-tag">{tg.tag}</span>
                        <span className="target-candidate-tag-description">{tg.tag === "foodstuff" ? "食材" : "レシピ"}</span>
                        <span className="target-candidate-name">{tg.name}</span>
                    </div>
                )}
            </div>
        </div>
    </div>
};

type SubstitutionTargetProps = {
    index_substitution: number,
    index_target: number,
    tag: string,
    name: string,
    target_id: string,
    quantity: string[],
    updateSubstitutionTargetQuantity: (index_substitution: number, index_target: number, top: number, bottom: number, unit: string) => void,
    deleteSubstitutionTarget: (index_substitution: number, index_target: number) => void,
};

const SubstitutionTarget = (props: SubstitutionTargetProps) => {
    const [quantityTop, setQuantityTop] = useState<number>(top_of_quantity(props.quantity));
    const [quantityBottom, setQuantityBottom] = useState<number>(bottom_of_quantity(props.quantity));
    const [quantityUnit, setQuantityUnit] = useState<string>(unit_of_quantity(props.quantity));

    return <div className="substitution-target">
        <span className="substitution-target-tag">{props.tag === "foodstuff" ? "食材" : "レシピ"}</span>
        <span className="substitution-target-id">{props.target_id}</span>
        <span className="substitution-target-name">{props.name}</span>
        <span className="substitution-target-quantity">
            <input type="number" className="substitution-target-quantity-top"
                min={0} max={1000000}
                defaultValue={quantityTop}
                onChange={(e) => {
                    let value = Number(e.target.value);
                    setQuantityTop(value);
                    props.updateSubstitutionTargetQuantity(
                        props.index_substitution, props.index_target,
                        value, quantityBottom, quantityUnit
                    );
                }}
            />/
            <input type="number" className="substitution-target-quantity-bottom"
                min={0} max={1000000}
                defaultValue={quantityBottom}
                onChange={(e) => {
                    let value = Number(e.target.value);
                    setQuantityBottom(value);
                    props.updateSubstitutionTargetQuantity(
                        props.index_substitution, props.index_target,
                        quantityTop, value, quantityUnit
                    );
                }}
            />
            <select className="substitution-target-quantity-unit"
                defaultValue={quantityUnit}
                onChange={(e) => {
                    let value = e.target.value;
                    setQuantityUnit(value);
                    props.updateSubstitutionTargetQuantity(
                        props.index_substitution, props.index_target,
                        quantityTop, quantityBottom, value
                    );
                }}
            >
                {units.map((unit, i_unit) =>
                    <option key={i_unit} value={unit[0]}>{unit[1]}</option>
                )}
            </select>
        </span>
        <span onClick={() => {
            props.deleteSubstitutionTarget(props.index_substitution, props.index_target);
        }}>x</span>
    </div>
};
